import { Box, Collapse, Flex } from '@chakra-ui/react';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { useMutation } from '@tanstack/react-query';
import {
  CSSProperties,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import BottomToolbarPlugin from './RichtextEditor/plugins/BottomToolbar';
import FloatingLinkEditorPlugin from './RichtextEditor/plugins/FloatingLink';
import MentionPlugin from './RichtextEditor/plugins/Mention';
import TopToolbarPlugin from './RichtextEditor/plugins/TopToolbar';

import { CustomSize } from '@/common/types';
import DisplayCommentBody from '@/components/workOrders/comments/DisplayCommentBody';
import { IChatMessage } from '@/modules/chats';
import type { IComment } from '@/modules/workOrders/types/comment';
import { IFile } from '@/utils/file/type';
import { gql } from '@apollo/client';
import { useRichMessageEditorSuspenseQuery } from './RichMessageEditor.generated';
import DisplayAttachments from './RichtextEditor/components/DisplayAttachments';
import DefaultNodes from './RichtextEditor/nodes/DefaultNodes';
import CommonPlugins from './RichtextEditor/plugins/CommonPlugins';
import EditorSubmitPlugin, {
  EditorSubmitPluginHandle,
} from './RichtextEditor/plugins/EditorSubmit';
import InsertInitialHtmlPlugin from './RichtextEditor/plugins/InsertInitialHtml';
import KeyEventsHandlerPlugin from './RichtextEditor/plugins/KeyEventsHandler';
import RichMessageEditorTheme from './RichtextEditor/utils/Theme';
import {
  IRichTextAttachment,
  IRichTextAttachmentInput,
  RichTextEditorMode,
} from './RichtextEditor/utils/types';
import { SuspenseWithSpinner } from './SuspenseWithSpinner';

export type SubmitInputType = {
  text: string; // HTMLの情報が入っている
  jsonText: string;
  plainText: string;
  attachments: IRichTextAttachmentInput[];
  deletedAttachmentFileIds?: string[];
  mentionIds: string[];
};

type RichMessageEditorType = {
  value?: string;
  valueLexical?: string;
  placeholder?: string;
  submit?: (input: SubmitInputType) => Promise<void>;
  mode?: RichTextEditorMode;
  onCancel?: () => void;
  attachments?: IRichTextAttachment[];
  replyComment?: IComment | IChatMessage;
  onGoToCommentClick?: (commentId: number) => void;
  submitPluginRef?: MutableRefObject<EditorSubmitPluginHandle | null>;
  allowSubmitEmpty?: boolean;
  hideSendButton?: boolean;
};

export type LocalAttachmentType = (IRichTextAttachmentInput | IRichTextAttachment)[];

export const SLIDE_SIZE: CustomSize = {
  width: 120,
  height: 100,
};

export const SLIDE_STYLE: CSSProperties = { paddingBottom: '20px' };

gql`
  query RichMessageEditor {
    users {
      ...MentionPluginUser_User
    }
  }
`;

const RichMessageEditor: FC<RichMessageEditorType> = (props: RichMessageEditorType) => {
  const {
    value,
    valueLexical,
    placeholder,
    submit,
    mode = 'add',
    onCancel,
    attachments,
    replyComment,
    onGoToCommentClick,
    submitPluginRef: pluginRefProp,
    allowSubmitEmpty = false,
    hideSendButton = false,
  } = props;

  const {
    data: { users },
  } = useRichMessageEditorSuspenseQuery();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: submit,
  });

  let submitPluginRef = useRef<EditorSubmitPluginHandle | null>(null);
  if (pluginRefProp) {
    submitPluginRef = pluginRefProp;
  }

  const onError = (error: Error) => {
    console.error(error);
  };

  // 表示するために変換した添付ファイル
  const [localAttachments, setLocalAttachments] = useState<LocalAttachmentType>([]);
  // 追加された添付ファイル
  const [localAddedAttachments, setLocalAddedAttachments] = useState<IRichTextAttachmentInput[]>(
    []
  );

  // 削除された添付ファイル
  const [localRemovedAttachmentFileIds, setLocalRemovedAttachmentFileIds] = useState<string[]>([]);

  const [showTopToolbar, setShowTopToolbar] = useState<boolean>(false);

  const initialConfig: InitialConfigType = {
    namespace: 'RichMessageEditor',
    theme: RichMessageEditorTheme,
    nodes: [...DefaultNodes],
    onError,
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement>();

  useEffect(() => {
    setLocalAttachments(attachments ?? []);
  }, [attachments]);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onSubmit = async (
    text: string,
    mentionIds: string[],
    jsonText: string,
    plainText: string
  ) => {
    if (
      !allowSubmitEmpty &&
      !text &&
      localAddedAttachments.length === 0 &&
      localRemovedAttachmentFileIds.length === 0
    )
      return;
    await mutateAsync({
      text,
      attachments: localAddedAttachments,
      mentionIds,
      jsonText,
      deletedAttachmentFileIds: localRemovedAttachmentFileIds,
      plainText,
    });
    // TODO: 成功するまで削除したくない
    setLocalAttachments([]);
    setLocalAddedAttachments([]);
    setLocalRemovedAttachmentFileIds([]);
  };

  const onAddFiles = (files: IFile[]) => {
    files.forEach(({ file, fileData, id }) => {
      setLocalAttachments((prevAttachments) => [
        ...prevAttachments,
        { contentType: file.type, src: fileData, name: file.name, fileId: id },
      ]);
      setLocalAddedAttachments((prevAttachmentInputs) => [
        ...prevAttachmentInputs,
        { contentType: file.type, name: file.name, fileId: id },
      ]);
    });
  };

  const removeFile = useCallback(
    (index: number) => {
      const attachments = [...localAttachments];

      const deletedAttachments = attachments.splice(index, 1);
      setLocalAttachments(attachments);

      if (deletedAttachments[0]) {
        const attachment = deletedAttachments[0] as IRichTextAttachment;
        if (attachment.id === undefined) {
          // 追加するファイルから削除
          setLocalAddedAttachments((prev) => {
            const index = prev.findIndex((prev) => prev.fileId === attachment.fileId);
            const newList = [...prev];
            newList.splice(index, 1);
            return newList;
          });
          return;
        }
        setLocalRemovedAttachmentFileIds((prev) => [...prev, attachment.fileId]);
      }
    },
    [localAttachments]
  );

  const onToggleFormatClick = () => {
    setShowTopToolbar((prev) => !prev);
  };

  const onCtrlEnterClick = () => {
    if (submitPluginRef.current) {
      submitPluginRef.current.send();
    }
  };

  return (
    <Box>
      <LexicalComposer initialConfig={initialConfig}>
        {mode === 'display' ? (
          <SuspenseWithSpinner>
            <DisplayCommentBody
              comment={value ?? ''}
              commentLexical={valueLexical}
              attachments={attachments}
              replyComment={replyComment}
              onGoToCommentClick={onGoToCommentClick}
            />
          </SuspenseWithSpinner>
        ) : (
          <Box p={1} border='1px' borderRadius='md' borderColor='gray.300'>
            <Collapse in={showTopToolbar} animateOpacity>
              <Flex>
                <TopToolbarPlugin />
              </Flex>
            </Collapse>
            <Box position='relative'>
              <RichTextPlugin
                contentEditable={
                  <Box as='div' ref={onRef} maxH={{ base: '72px', md: '180px' }} overflowY='auto'>
                    <ContentEditable className='richtext-editor' />
                  </Box>
                }
                placeholder={
                  <Box position='absolute' top='3' left='3' color='gray.500' zIndex='0'>
                    {placeholder}
                  </Box>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <DisplayAttachments localAttachments={localAttachments} removeFile={removeFile} />

              <Flex flex='1' justifyContent='end' mt='2'>
                <BottomToolbarPlugin
                  flex='1'
                  isActiveToggleFormat={showTopToolbar}
                  onFilesAdded={onAddFiles}
                  onToggleFormatClick={onToggleFormatClick}
                />
                <EditorSubmitPlugin
                  ref={submitPluginRef}
                  mode={mode}
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                  hideSendButton={hideSendButton}
                  isLoading={isPending}
                />
              </Flex>
            </Box>
          </Box>
        )}
        <InsertInitialHtmlPlugin value={value} valueLexical={valueLexical} />
        <CommonPlugins />
        <MentionPlugin users={users} />
        <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
        <KeyEventsHandlerPlugin onCtrlEnterClick={onCtrlEnterClick} />
      </LexicalComposer>
    </Box>
  );
};

const RichMessageEditorWithSuspense: FC<RichMessageEditorType> = (props: RichMessageEditorType) => (
  <SuspenseWithSpinner>
    <RichMessageEditor {...props} />
  </SuspenseWithSpinner>
);

export default RichMessageEditorWithSuspense;
